import React from 'react';
import hustler from '../assets/hustler.gif';

const LoadingAnimation = () => {
    return(
        <div className='relative w-screen h-screen flex flex-col justify-center items-center'>
            <div className='font-monoton xs:text-4xl lg:text-7xl xl:text-jumbo mobile-landscape:text-6xl text-pink-lace font-normal absolute text-shadow-monoton-neon tracking-[8px] xs:top-[35vmax] lg:top-[30vmax] xl:top-[25vmin] mobile-landscape:top-[20vmin]' data-text='U'>
                LO
                <span className='animate-flicker-slow'>
                A
                </span>
                D
                <span className='animate-flicker-fast'>
                I
                </span>
                NG
                <span className='animate-flicker-slow'>
                ...
                </span>
            </div>
            <div className='bg-white bg-opacity-10 justify-start rounded-full items-center relative flex xs:top-[5vmax] xl:top-[12vmin] mobile-landscape:top-[18vmin] px-[5px] xs:h-[4vmax] xl:h-[3.75vmax] xs:w-[30vmax] xl:w-[29vmax] mobile-landscape:w-[40vmax]'>
                <div className='animate-progress-bar box-shadow-neon bg-mud bg-cover rounded-full h-[2.9vmax] w-[29vmax]'>
                <img className='xs:animate-running-hustler-xs xl:animate-running-hustler-xl lg:animate-running-hustler-lg mobile-landscape:animate-running-hustler-mobile-landscape xs:h-[5vmax] xl:h-[2.9vmax]' src={hustler} alt='Hoof Hustler' />
                </div>
            </div>
        </div>
    );
};

export default LoadingAnimation;
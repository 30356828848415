import React, { useState, useEffect } from 'react';
import LoadingAnimation from './components/LoadingAnimation.js';
import HomePage from './components/HomePage.js';

function App() {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Simulate a loading time with a timeout
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 5000); // 5000 ms = 5 seconds

    return () => clearTimeout(timer);
  }, []);

  return (
    <div className="App">
      {isLoading ? <LoadingAnimation /> : <HomePage />}
    </div>
  );
}

export default App;

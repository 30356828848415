import React, { useEffect } from 'react';
import logo from '../assets/hoofhustlers-logo.png';
import stadium from '../assets/Layer 3.png';
import hustler from '../assets/hustler-left.gif';
import hustlerMax from '../assets/hustlerMax.png';
import frame1 from '../assets/frame 1.png';
import frame2 from '../assets/frame 2.png';
import frame3 from '../assets/frame 3.png';
import frame4 from '../assets/frame 4.png';
import buttonBgWhite from '../assets/pixel_whitebackdrop.png';
import blueBgWhite from '../assets/pixel_bluebackdrop.png';
import discordLogo from '../assets/discordLogo.png';
import twitterLogo from '../assets/twitterLogo.png';


const HomePage = () => {
    const handleAnimationEnd = () => {
        const image = document.querySelector('.arena-ground-running-hustler');
        image.src = hustlerMax; // Change the image source to the new image
    };

    useEffect(() => {
        const image = document.querySelector('.arena-ground-running-hustler');
        image.addEventListener('animationend', handleAnimationEnd);

        return () => {
            image.removeEventListener('animationend', handleAnimationEnd);
        };
    }, []);

    return (
        <div className='arena overflow-hidden cursor-fancy-arrow'>
            <div className='arena-spectate relative w-screen z-0 flex-row bg-[linear-gradient(0deg,_rgba(227,163,255,1)_0%,_rgba(43,190,237,1)_80%,_rgba(6,195,233,1)_100%)]'>
                <div className='arena-spectate-wrapper flex'>
                    <div className='arena-stands xs:hidden xl:flex w-[50vw] z-10'>
                        <img className='top-0 left-0 w-[50vw] max-h-[90vh]' src={stadium} alt=''/>
                    </div>
                    <div className='arena-info flex justify-center items-center xl:w-[50vw] xs:h-[93vh] xl:h-[90vh]'>
                        <div className='arena-info-gallery gap-5 h-[150%] b-0 -rotate-[35deg] opacity-50'>
                            <div className='arena-info-gallery-slide gap-5 flex'>
                                <div className='arena-info-gallery-slide-show flex shrink-0 animate-slide-transition-slow'>
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                </div>
                                <div className='arena-info-gallery-slide-show flex shrink-0 animate-slide-transition-slow'>
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                </div>
                            </div>
                            <div className='arena-info-gallery-slide gap-5 flex'>
                                <div className='arena-info-gallery-slide-show flex shrink-0 animate-slide-transition-fast'>
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                </div>
                                <div className='arena-info-gallery-slide-show flex shrink-0 animate-slide-transition-fast'>
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                </div>
                            </div>
                            <div className='arena-info-gallery-slide gap-5 flex'>
                                <div className='arena-info-gallery-slide-show flex shrink-0 animate-slide-transition-slow'>
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                </div>
                                <div className='arena-info-gallery-slide-show flex shrink-0 animate-slide-transition-slow'>
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                </div>
                            </div>
                            <div className='arena-info-gallery-slide gap-5 flex'>
                                <div className='arena-info-gallery-slide-show flex shrink-0 animate-slide-transition-fast'>
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                </div>
                                <div className='arena-info-gallery-slide-show flex shrink-0 animate-slide-transition-fast'>
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame1} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame2} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame3} alt='' />
                                    <img className='h-[27vh] aspect-w-1 aspect-h-1.5 object-cover' src={frame4} alt='' />
                                </div>
                            </div>
                        </div>
                        <div className='arena-info-banner bg-cover bg-center bg-no-repeat absolute xs:bg-pixel-disco-xs xl:bg-pixel-disco-xl xs:w-[50vmax] xl:w-[45vmax] xs:h-[70vh] xl:h-[70vh] z-10'>
                            <img className='arena-info-banner-logo mx-auto xl:my-5 transform xs:scale-[0.55] xl:scale-[0.65]' src={logo} alt='logo' />
                            <div className='arena-info-banner-social flex flex-col items-center xs:mt-[12vh] xl:mt-20'>
                                <button className='arena-info-banner-social-button flex relative item-center rounded-2xl shadow-black-backdrop cursor-fancy-pointer xl:mb-5 xs:scale-[0.75] xl:scale-[1]'>
                                    <img className='social-button-bg mx-auto z-10' src={buttonBgWhite} alt=''/>
                                    <div className='absolute flex items-center h-auto z-20'>
                                        <img className='social-button-logo mx-3 my-2' src={twitterLogo} alt='twitter' />
                                        <p className='social-button-identifier-text font-pixeled mx-1 -translate-y-1 text-[1.6vh]'>HOOF HUSTLERS</p>
                                    </div>
                                </button>
                                <button className='arena-info-banner-social-button flex relative item-center rounded-2xl shadow-black-backdrop cursor-fancy-pointer xl:mb-5 xs:scale-[0.75] xl:scale-[1]'>
                                    <img className='social-button-bg mx-auto z-10' src={buttonBgWhite} alt=''/>
                                    <div className='absolute flex items-center h-auto z-20'>
                                        <img className='social-button-logo mx-3 my-2' src={discordLogo} alt='discord channel' />
                                        <p className='social-button-identifier-text font-pixeled mx-1 -translate-y-1 text-[1.6vh]'>HOOF HUSTLERS</p>
                                    </div>
                                </button>
                                <button className='arena-info-banner-early-access-button flex relative item-center rounded-3xl shadow-white-backdrop cursor-fancy-pointer mt-3 xs:scale-[0.75] xl:scale-[1]'>
                                    <img className='early-access-button-bg mx-auto z-10 ' src={blueBgWhite} alt='' />
                                    <div className='absolute flex items-center h-full w-full z-20'>
                                        <p className='font-pixeled mx-auto text-center -translate-y-1 text-[1.6vh]'>GET EARLY ACCESS</p>
                                    </div>    
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='arena-ground bg-mud relative flex z-20 bottom-0 xs:h-[7vh] xl:h-[10vh]'>
                <img className='arena-ground-running-hustler absolute ease-out xs:bottom-[6.68vh] xl:bottom-[9.69vh] xs:animate-running-hustler-rtl-xs xl:animate-running-hustler-rtl-xl' src={hustler} alt=''/>
            </div>
        </div>
    );
};

export default HomePage;
